import React, { useEffect, useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';

import AppNotify from '../AppNotify/AppNotify';
import UserMenu from '../UserMenu/UserMenu';
import FaqView from '../../views/Faq/Faq';

import useOutsideClick from '../../../util/useOutsideClick';
import { getCustomState } from '../../../util/LocalStorage';
import { categories, SESSION_STATE, USER_DATA } from '../../../util/constants/AppConstants';

import UserIcon from '../../../assets/images/icons/user.svg';
import ArrowIconWhite from '../../../assets/images/icons/arrow-right-white.svg';
import 'react-perfect-scrollbar/dist/css/styles.css';
import './Header.scss';

const NavPanel = () => {
  const [isUserMenuOpened, setIsUserMenuOpened] = useState(false);
  const [notifyNum, setNotifyNum] = useState(0);
  const [faqPage, setFaqPage] = useState(false);
  const [isDropdownOpened, setIsDropdownOpened] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState(categories[0]);

  const [userDataState, setUserDataState] = useState(false);
  const [userInfo, setUserInfo] = useState(false);

  const [dataFetching, setDataFetching] = useState(false);

  const config = useSelector((state) => state.config.config);
  const actions = useSelector((state) => state.action);
  const session = getCustomState(SESSION_STATE);

  const dropdownRef = useRef();
  const dropdownLabelRef = useRef();
  const profileRef = useRef();
  const profileIcon = useRef();
  const scrollbarRef = useRef();

  const { pathname } = useLocation();

  const isSearches = pathname === '/';

  const handleGetNewData = () => {
    const userData = getCustomState(USER_DATA, true);
    setUserInfo(userData);
  };

  useEffect(() => {
    if (scrollbarRef.current) {
      scrollbarRef.current.scrollTop = 0;
    }
  }, [pathname]);

  useEffect(() => {
    if (config.api && session && !dataFetching) {
      const userData = getCustomState(USER_DATA, true);
      handleGetNewData(0, userData.id);
      setDataFetching(true);
    }
  }, [config, session, userDataState, dataFetching]);

  useEffect(() => {
    setUserDataState(getCustomState(USER_DATA, true));
  }, []);

  useEffect(() => {
    if (!userInfo && userDataState && config.api && !actions.loggingIn) {
      setUserInfo(userDataState);
    }
  }, [userDataState, userInfo, config, actions]);

  const handleOpenUserMenu = () => {
    setIsUserMenuOpened((prevState) => !prevState);
  };

  const handleFaqPage = () => {
    setFaqPage((prevState) => !prevState);
    setIsUserMenuOpened(false);
  };

  const handleSearchesClick = (e) => {
    // if (isSearches) {
    //   e.preventDefault();
    //   setIsDropdownOpened((prev) => !prev);
    // }
  };

  const handleCategoryClick = (category) => {
    setSelectedCategory(category);
    setIsDropdownOpened(false);
  };

  useOutsideClick(dropdownRef, dropdownLabelRef, isDropdownOpened, () => setIsDropdownOpened(false));
  useOutsideClick(profileRef, profileIcon, isUserMenuOpened, () => setIsUserMenuOpened(false));

  return (
    <>
      <AppNotify />
      <div className="navigation">
        <nav className="navigation__menu">
          <ul className="navigation__links">
            <li>
              <NavLink ref={dropdownLabelRef} className="navigation__link" to="/" onClick={handleSearchesClick}>
                {selectedCategory}
                {/* {isSearches && (
                  <img className={`navigation__arrow ${isDropdownOpened ? 'rotated' : ''}`} src={ArrowIconWhite} />
                )} */}
              </NavLink>
              {isDropdownOpened && (
                <div
                  className={`options-wrapper ${
                    categories.length === 1 ? 's' : categories.length === 2 ? 'm' : categories.length === 3 ? 'l' : ''
                  }`}
                  ref={dropdownRef}
                >
                  <PerfectScrollbar
                    options={{
                      wheelPropagation: false,
                      autoHide: false,
                    }}
                  >
                    <ul className="options-list">
                      {categories.map((category, index) => (
                        <li key={index} onClick={() => handleCategoryClick(category)}>
                          {category}
                        </li>
                      ))}
                    </ul>
                  </PerfectScrollbar>
                </div>
              )}
            </li>
            {userInfo.role === 1 && (
              <li>
                <NavLink className="navigation__link" to="/elections">
                  Elections
                </NavLink>
              </li>
            )}
            {userInfo.role === 1 && (
              <li>
                <NavLink className="navigation__link" to="/maps">
                  Maps
                </NavLink>
              </li>
            )}
          </ul>
        </nav>
        <div ref={profileIcon} className={`user-icon ${isUserMenuOpened ? 'opened' : ''}`} onClick={handleOpenUserMenu}>
          {notifyNum > 0 ? <div className="notify-num">{notifyNum}</div> : ''}
          {userInfo ? Array.from(userInfo.firstname)[0] + Array.from(userInfo.lastname)[0] : <img src={UserIcon} />}
        </div>
        {isUserMenuOpened && (
          <UserMenu
            profileRef={profileRef}
            setIsOpen={setIsUserMenuOpened}
            userInfo={userInfo}
            handleFaqPage={handleFaqPage}
          />
        )}
      </div>
      <div className="outlet-wrap">
        <PerfectScrollbar
          ref={scrollbarRef}
          options={{
            wheelPropagation: true,
            autoHide: false,
          }}
        >
          <Outlet context={[setFaqPage, userDataState]} />
        </PerfectScrollbar>
      </div>
      {faqPage && <FaqView isOpen={faqPage} handleFaqPage={handleFaqPage} />}
      <div className="background-elem"></div>
    </>
  );
};

export default NavPanel;
