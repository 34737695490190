import { createStore } from 'redux';

import allReducers from './reducers/index';
import { loadState } from '../util/LocalStorage';
import config from '../config.json';

const persistedState = loadState();
const store =
  config.env === 'dev'
    ? createStore(
        allReducers,
        persistedState,
        window.__REDUX_DEVTOOLS_EXTENSION__ && window.__REDUX_DEVTOOLS_EXTENSION__(),
      )
    : createStore(allReducers, persistedState);

export default store;
