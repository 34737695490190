const init = {
  backgroundEffect: true,
};

const settingsReducer = (state = { ...init }, action) => {
  switch (action.type) {
    case 'SET_BACKGROUND_EFFECT':
      return {
        ...state,
        backgroundEffect: action.backgroundEffect,
      };

    default:
      return state;
  }
};

export default settingsReducer;
