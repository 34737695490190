export const loadState = () => {
  try {
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem('state', serializedState);
  } catch {}
};

export const setState = (label, data) => {
  try {
    const serializedState = JSON.stringify(data);
    localStorage.setItem(label, data);
  } catch {}
};

export const getState = (label, parse = true) => {
  try {
    const serializedState = localStorage.getItem(label);
    if (serializedState === null) {
      return undefined;
    }
    if (!parse) {
      return serializedState;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const deleteState = (label) => {
  try {
    localStorage.removeItem(label);
  } catch {}
};

export const saveCustomState = (name, data, json = false) => {
  let stateData = data;
  if (json) {
    stateData = JSON.stringify(stateData);
  }
  localStorage.setItem(name, stateData);
};

export const getCustomState = (name, json = false) => {
  const value = localStorage.getItem(name);
  if (json) {
    return JSON.parse(value);
  }
  return value;
};

export const deleteCustomState = (name) => {
  localStorage.removeItem(name);
};
