const init = {
  session: '',
  session_id: '',
};

const sessionReducer = (state = { ...init }, action) => {
  switch (action.type) {
    case 'SET_SESSION':
      return {
        ...state,
        session: action.session,
      };

    case 'SET_SESSION_ID':
      return {
        ...state,
        session_id: action.session_id,
      };

    default:
      return state;
  }
};

export default sessionReducer;
