import React from 'react';

import SocialPost from '../SocialPost/SocialPost';

const TwitterPost = ({ data }) => {
  const TITLE = 'X (Formally Known As Twitter)';
  const ICON = 'https://x.com';

  return (
    <SocialPost name={data.source} icon={data.icon} content={data.text} username={data.username} date={data.date} />
  );
};

export default TwitterPost;
