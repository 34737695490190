import React, { useState, useMemo } from 'react';

import { truncate } from '../../../../util/HelperFunctions';

import LinkIcon from '../../../../assets/images/icons/link.svg';
import LinkOpenIcon from '../../../../assets/images/icons/link-open.svg';
import ArrowRightIcon from '../../../../assets/images/icons/arrow-right.svg';
import './DataGrid.scss';

const DataGridComponent = ({ data, type = 'link', truncateText = true, useNumbers = true, dataNumber = 0 }) => {
  const [total, setTotal] = useState(0);

  const handleOpenLink = () => {
    if (type === 'link') {
      window.open(data.full_link, '_blank', 'noreferrer');
    }
  };

  useMemo(() => {
    const number = Intl.NumberFormat('en-US').format(data.total);
    setTotal(number);
  }, [total]);

  return (
    <div className="data-link">
      <div className="data-link-left" onClick={handleOpenLink}>
        {type === 'link' ? (
          <>
            {useNumbers ? <span className="list-number">{dataNumber}</span> : <img src={LinkIcon} />}
            <span>{truncateText ? truncate(data.link, 25) : data.link}</span>
            <input type="text" value={data.link} onChange={() => {}} />
          </>
        ) : (
          <>
            {useNumbers ? (
              <span className="list-number">{dataNumber}</span>
            ) : (
              <img src={ArrowRightIcon} className="sm" />
            )}
            <span>{truncateText ? truncate(data.text, 16) : data.text}</span>
            <input type="text" value={data.text} onChange={() => {}} />
          </>
        )}
      </div>
      <div className="data-link-icon">
        <span>({total})</span>
        {type === 'link' ? <img src={LinkOpenIcon} onClick={handleOpenLink} /> : ''}
      </div>
    </div>
  );
};

export default DataGridComponent;
