import React from 'react';

import './Tooltip.scss';

const Tooltip = ({ content, x, y }) => {
  return (
    <div
      className="tooltip-window"
      style={{
        position: 'fixed',
        top: y + 10,
        left: x + 10,
      }}
    >
      {content}
    </div>
  );
};

export default Tooltip;
