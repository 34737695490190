import React from 'react';

import { SocialIcon } from 'react-social-icons';

import TimeIcon from '../../../../assets/images/icons/time.svg';
import './SocialPost.scss';

const SocialPost = ({ icon, name, content, username, date }) => {
  const capitalizeFirstLetter = (string) => {
    if (!string) {
      return '';
    }
    return string.charAt(0).toUpperCase() + string.slice(1);
  };

  return (
    <div className="social-post">
      <div className="social-header">
        <div className="social-header-icon">
          <SocialIcon url={icon} />
        </div>
        <div className="social-header-label">
          {name}
          {name !== 'News' ? <span>{username}</span> : ''}
        </div>
      </div>
      <div className="social-text">
        {name === 'News' ? (
          <>
            <div className="news-content primary">{capitalizeFirstLetter(username)}</div>
            <div className="news-content">{content}</div>
          </>
        ) : (
          content
        )}
      </div>
      <div className="social-date">
        <img src={TimeIcon} /> Posted On: {date}
      </div>
    </div>
  );
};

export default SocialPost;
