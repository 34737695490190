const init = {
  loggingIn: false,
  notify: {
    status: false,
    type: null,
    title: '',
    message: '',
  },
};

const actionReducer = (state = { ...init }, action) => {
  switch (action.type) {
    case 'SET_LOGIN_ACTION':
      return {
        ...state,
        loggingIn: action.loggingIn,
      };

    case 'SET_NOTIFY_ACTION':
      return {
        ...state,
        notify: action.notify,
      };

    default:
      return state;
  }
};

export default actionReducer;
