import React, { useState, useEffect } from 'react';
import { render } from 'react-dom';
import WordCloud from 'react-d3-cloud';
import { scaleOrdinal } from 'd3-scale';
import { schemeCategory10 } from 'd3-scale-chromatic';
import Fader from '../Loaders/Fader';

const WordCloudComponent = ({ data = [], handleWordHover }) => {
  const schemeCategory10ScaleOrdinal = scaleOrdinal(['#376EBA']);

  const [diffValue, setDiffValue] = useState(0);
  const [baseLineFontSize, setBaseLineFontSize] = useState(325);
  const [baseLineMultiplier, setBaseLineMultiplier] = useState(0.72);

  const [height, setHeight] = useState(120);
  const [width, setWidth] = useState(200);

  const [isHover, setIsHover] = useState(false);
  const [value, setValue] = useState(data.value);

  useEffect(() => {
    if (data && data[0]) {
      let largeVal = data[0].value;
      let smallVal = data[data.length - 1].value;
      setDiffValue(largeVal);
    }
  }, [data]);

  useEffect(() => {
    if (diffValue > 10000) {
      setBaseLineFontSize(79);
    }
    if (diffValue > 3 && diffValue < 10000) {
      setBaseLineFontSize(325);
    }
  }, [diffValue]);

  if (data.length === 0) {
    return <div className="no-data">No Hashtags for this Search</div>;
  }

  return (
    <>
      <WordCloud
        data={data}
        width={width}
        height={height}
        font="sans-serif"
        fontWeight="bold"
        fontSize={(word) => (baseLineFontSize * (Math.log2(word.value) * baseLineMultiplier)) / 100}
        spiral="rectangular"
        rotate={(word) => 0}
        padding={2}
        fill={(d, i) => schemeCategory10ScaleOrdinal(i)}
        onWordClick={(event, d) => {
          console.log(`onWordClick: ${d.text}`);
        }}
        onWordMouseOver={(event, d) => {
          handleWordHover();
        }}
        onWordMouseOut={(event, d) => {
          //setIsHover(false);
        }}
      />
    </>
  );
};

export default WordCloudComponent;
