export const map = {
  district: 'Bay of Quinte',
  atlasMap: 'BayofQuinteAtlasMap.pdf',
  detailedMap: 'BayofQuinteDetailedOverviewMap.pdf',
};

// export const map = {
//   district: 'Brampton North',
//   atlasMap: 'BramptonNorthAtlasMap.pdf',
//   detailedMap: 'BramptonNorthDetailedOverviewMap.pdf',
// };

// export const map = {
//   district: 'Kenora—Rainy River',
//   atlasMap: 'KenoraRainyRiverAtlasMap.pdf',
//   detailedMap: 'KenoraRainyRiverDetailedOverviewMap.pdf',
// };
