import React, { useState, useEffect } from 'react';

import TopicIcon from '../../../../assets/images/icons/topic.svg';
import TopicIconWhite from '../../../../assets/images/icons/topic-white.svg';
import ArrowIcon from '../../../../assets/images/icons/arrow-right.svg';
import ArrowIconWhite from '../../../../assets/images/icons/arrow-right-white.svg';
import './TopicItem.scss';

const TopicItem = ({ data, icon = false, iconHover = false, topicHandler, activeTopic = false }) => {
  const [isHover, setIsHover] = useState(false);
  const [iconDisplay, setIconDisplay] = useState(TopicIcon);
  const [arrowDisplay, setArrowDisplay] = useState(ArrowIcon);
  const [activeTopicStatus, setActiveTopicStatus] = useState(false);

  const setTopicHandler = () => {
    topicHandler(data.name, data.id);
  };

  const handleHover = () => {
    setIsHover(true);
    setIconDisplay(TopicIconWhite);
    setArrowDisplay(ArrowIconWhite);
  };

  const handleExit = () => {
    setIsHover(false);
    setIconDisplay(TopicIcon);
    setArrowDisplay(ArrowIcon);
  };

  useEffect(() => {
    if (icon && !isHover) {
      setIconDisplay(icon);
    }
    if (iconHover && isHover) {
      setIconDisplay(iconHover);
    }
    if (activeTopic && activeTopic === data.id) {
      setActiveTopicStatus(true);
      return;
    }
    setActiveTopicStatus(false);
  });

  return (
    <div
      className={`menu-topic ${activeTopicStatus ? 'active' : ''}`}
      onClick={setTopicHandler}
      onMouseEnter={handleHover}
      onMouseLeave={handleExit}
    >
      <div className="menu-topic-icon">
        <img src={activeTopicStatus ? TopicIconWhite : iconDisplay} />
      </div>
      <div className="menu-topic-label">{data.name}</div>
      <div className="menu-topic-go">
        <img src={arrowDisplay} />
      </div>
    </div>
  );
};

export default TopicItem;
