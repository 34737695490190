export const setLoggingIn = (payload) => {
  return {
    type: 'SET_LOGIN_ACTION',
    loggingIn: payload,
  };
};

export const setNotify = (payload) => {
  return {
    type: 'SET_NOTIFY_ACTION',
    notify: payload,
  };
};
