import React from 'react';
import { MapContainer, TileLayer, GeoJSON, useMap } from 'react-leaflet';
import L from 'leaflet';

import bramptonWardsGeoJSON from '../../../util/constants/bramptonWardsGeoJSON.json';

import 'leaflet/dist/leaflet.css';
import './WardsMap.scss';

const style = (feature) => {
  return {
    fillColor: feature.properties.fillColor,
    weight: 1,
    color: '#2f4f4f',
    fillOpacity: 0.4,
  };
};

const CustomWardMarker = ({ position, content }) => {
  const map = useMap();

  React.useEffect(() => {
    const customIcon = L.divIcon({
      className: 'ward-label',
      html: `${content}`,
    });

    const marker = L.marker(position, { icon: customIcon }).addTo(map);

    return () => {
      map.removeLayer(marker);
    };
  }, [map, position, content]);

  return null;
};

const WardsMap = () => {
  const onEachWard = (ward, layer) => {
    const wardName = `WARD ${ward.properties.number}`;

    if (ward.properties.number !== 6) {
      layer.bindTooltip(wardName, { permanent: true, direction: 'center', className: 'ward-label' });
    }
  };

  return (
    <MapContainer
      style={{ height: '80vh', width: '100%' }}
      center={[43.7315, -79.7624]}
      zoom={11}
      scrollWheelZoom={true}
    >
      <TileLayer
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      <GeoJSON data={bramptonWardsGeoJSON} onEachFeature={onEachWard} style={style} />
      <CustomWardMarker position={[43.671, -79.864]} content="WARD 6" />
    </MapContainer>
  );
};

export default WardsMap;
