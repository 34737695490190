import React from 'react';

import { PieChart } from '@mui/x-charts/PieChart';

import './PieChart.scss';

const PieChartComponent = ({
  positiveColor = '#05B08A',
  positive = 0,
  neutralColor = '#606161',
  neutral = 0,
  negativeColor = '#C4001F',
  negative = 0,
}) => {
  const pieParams = { height: 210, legend: { hidden: true } };

  return (
    <div className="sentiment-pie">
      <PieChart
        series={[
          {
            data: [
              { id: 0, value: positive, label: 'Positive', color: positiveColor },
              { id: 1, value: negative, label: 'Negative', color: negativeColor },
              { id: 2, value: neutral, label: 'Neutral', color: neutralColor },
            ],
            innerRadius: 80,
            outerRadius: 100,
            paddingAngle: 0,
            cornerRadius: 0,
            startAngle: 0,
            endAngle: 360,
            cx: 100,
            cy: 100,
          },
        ]}
        {...pieParams}
      />
    </div>
  );
};

export default PieChartComponent;
