import { combineReducers } from 'redux';
import configReducer from './configReducer';
import sessionReducer from './sessionReducer';
import actionReducer from './actionReducer';
import settingsReducer from './settingsReducer';

export default combineReducers({
  action: actionReducer,
  config: configReducer,
  session: sessionReducer,
  settings: settingsReducer,
});
