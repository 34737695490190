import React, { useRef, useState } from 'react';

import Table from 'react-bootstrap/Table';

import useOutsideClick from '../../../util/useOutsideClick';
import { elections } from '../../../util/constants/fakeJson';
import { formatNumber, formatNumberWithCommas } from '../../../util/HelperFunctions';

import './Elections.scss';

const Elections = () => {
  const [changeInfo, setChangeInfo] = useState(false);
  const [swingInfo, setSwingInfo] = useState(false);

  const changeRef = useRef();
  const changeIconRef = useRef();
  const swingRef = useRef();
  const swingIconRef = useRef();

  useOutsideClick(changeRef, changeIconRef, changeInfo, () => setChangeInfo(false));
  useOutsideClick(swingRef, swingIconRef, swingInfo, () => setSwingInfo(false));

  const toggleState = (setState) => {
    setState((prevState) => !prevState);
  };

  return (
    <div className="platform-frame">
      <div className="elections">
        <div className="pfr-header">
          <div className="dashboard-title">{`${elections.election}: ${elections.district}`}</div>
        </div>
        <div className="chart-component">
          <Table responsive bordered hover>
            <thead>
              <tr>
                <th>Party</th>
                <th>Candidate</th>
                <th>Votes</th>
                <th>%</th>
                <th className="info-cell">
                  ±%
                  <div ref={changeIconRef} className="info-icon" onClick={() => toggleState(setChangeInfo)}>
                    i
                  </div>
                  <div className={`info-box ${changeInfo ? '' : 'hidden'}`} ref={changeRef}>
                    <div className="ii-copy">
                      <div className="iic-exp">Change in percentage value since previous election</div>
                    </div>
                  </div>
                </th>
                {elections.expenseLimit && <th>Expenditures</th>}
              </tr>
            </thead>
            <tbody>
              {elections.candidates.map((candidate, index) => (
                <tr key={index}>
                  <td className="party__name">
                    <div className="party__color" style={{ background: candidate.partyColor }}></div>
                    {candidate.party}
                  </td>
                  <td className={index === 0 ? 'bold' : ''}>{candidate.candidate}</td>
                  <td>{formatNumberWithCommas(candidate.votes)}</td>
                  <td>{formatNumber(candidate.percent)}</td>
                  <td>
                    {candidate.percentChange
                      ? `${candidate.isChangePositive ? '+' : '-'}${formatNumber(candidate.percentChange)}`
                      : ''}
                  </td>
                  {elections.expenseLimit && <td>{`$${formatNumberWithCommas(candidate.expenditures)}`}</td>}
                </tr>
              ))}
              <tr className="total">
                <td colSpan={2}>{`Total valid votes${elections.expenseLimit ? '/Expense limit' : ''}`}</td>
                <td>{formatNumberWithCommas(elections.validVotes.total)}</td>
                <td>{formatNumber(elections.validVotes.percent)}</td>
                <td>
                  {elections.validVotes.percentChange
                    ? `${elections.validVotes.isChangePositive ? '+' : '-'}${formatNumber(elections.validVotes.percentChange)}`
                    : ''}
                </td>
                {elections.expenseLimit && <td>{`$${formatNumberWithCommas(elections.expenseLimit)}`}</td>}
              </tr>
              <tr className="total">
                <td colSpan={2}>Total rejected, unmarked, and declined ballots</td>
                <td>{formatNumberWithCommas(elections.rejectedVotes.total)}</td>
                <td>{elections.rejectedVotes.percent ? formatNumber(elections.rejectedVotes.percent) : ''}</td>
                <td>
                  {elections.rejectedVotes.percentChange
                    ? `${elections.rejectedVotes.isChangePositive ? '+' : '-'}${formatNumber(elections.rejectedVotes.percentChange)}`
                    : ''}
                </td>
                {elections.expenseLimit && <td></td>}
              </tr>
              <tr className="total">
                <td colSpan={2}>Turnout</td>
                <td>{formatNumberWithCommas(elections.turnout.total)}</td>
                <td>{formatNumber(elections.turnout.percent)}</td>
                <td>
                  {elections.turnout.percentChange
                    ? `${elections.turnout.isChangePositive ? '+' : '-'}${formatNumber(elections.turnout.percentChange)}`
                    : ''}
                </td>
                {elections.expenseLimit && <td></td>}
              </tr>
              <tr className="total">
                <td colSpan={2}>Eligible voters</td>
                <td>{formatNumberWithCommas(elections.eligibleVoters)}</td>
                <td></td>
                <td></td>
                {elections.expenseLimit && <td></td>}
              </tr>
              <tr>
                <td colSpan={2} className="party__name">
                  <div
                    className="party__color"
                    style={{
                      background: elections.candidates.find((candidate) => candidate.party === elections.winner)
                        .partyColor,
                    }}
                  ></div>
                  {elections.winner}
                  {elections.prevWinner ? (
                    <>
                      <span className="bold"> gain</span> from {elections.prevWinner}
                    </>
                  ) : (
                    <span className="bold"> hold</span>
                  )}
                </td>
                <td colSpan={2} className="swing info-cell">
                  Swing
                  <div ref={swingIconRef} className="info-icon" onClick={() => toggleState(setSwingInfo)}>
                    i
                  </div>
                  <div className={`info-box ${swingInfo ? '' : 'hidden'}`} ref={swingRef}>
                    <div className="ii-copy">
                      <div className="iic-exp">
                        An electoral swing analysis (or swing) shows the extent of change in voter support, typically
                        from one election to another, expressed as a positive or negative percentage
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  {elections.swing.percentChange
                    ? `${elections.swing.isChangePositive ? '+' : '-'}${formatNumber(elections.swing.percentChange)}`
                    : ''}
                </td>
                {elections.expenseLimit && <td></td>}
              </tr>
            </tbody>
          </Table>
        </div>
      </div>
    </div>
  );
};

export default Elections;
