const init = {
  config: [],
};

const configReducer = (state = { ...init }, action) => {
  switch (action.type) {
    case 'SET_CONFIG':
      return {
        ...state,
        config: action.config,
      };

    default:
      return state;
  }
};

export default configReducer;
