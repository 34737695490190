import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import PerfectScrollbar from 'react-perfect-scrollbar';
import TopicItem from './TopicItem/TopicItem';
import Fader from '../Loaders/Fader';

import { getTopics } from '../../../http/dataAPI';
import { getCustomState } from '../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../util/constants/AppConstants';

import 'react-perfect-scrollbar/dist/css/styles.css';
import Logo from '../../../assets/images/logo.png';
import './Topics.scss';

const Topics = ({ isOpen, setIsOpen, menuRef, title, setTitle, activeTopicId, setActiveTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [dataSources, setDataSources] = useState([]);

  const handleGetDataSources = async (id, userId) => {
    setLoading(true);

    const res = await getTopics(config, id, userId);
    if (res.status === 'success') {
      setDataSources(res.data);
      if (res.data.length !== 0) {
        if (!title) {
          setTitle(res.data[0].name);
        }
        if (id === 0) {
          setActiveTopicId(res.data[0].id);
        }
      }
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Topics');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetDataSources(0, userData.id);
    }
  }, [config, session, userDataState]);

  const handleTopicSelection = (label, id) => {
    setTitle(label);
    setIsOpen(false);
    window.scrollTo({ top: 0, behavior: 'smooth' });
    setActiveTopicId(id);
  };

  return (
    <div className={`pf-left ${isOpen ? 'open' : ''}`} ref={menuRef}>
      <div className="logo">
        <img src={Logo} />
      </div>
      <div className="menu">
        {!loading ? (
          <PerfectScrollbar
            options={{
              wheelPropagation: false,
              autoHide: false,
              wheelSpeed: 0.7,
            }}
          >
            <div className="menu-content">
              {dataSources.map((item) => (
                <TopicItem key={item.id} data={item} topicHandler={handleTopicSelection} activeTopic={activeTopicId} />
              ))}
            </div>
          </PerfectScrollbar>
        ) : (
          <div className="sentiment-charts">
            <Fader />
          </div>
        )}
      </div>
      <div className="menu-footer">
        <div className="disclaimer">&copy; 2024 TAGA Group Inc.</div>
      </div>
    </div>
  );
};

export default Topics;
