import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import WordCloudComponent from '../../Charts/WordCloud';
import Fader from '../../Loaders/Fader';

import { getTopHashtags } from '../../../../http/dataAPI';
import { getCustomState } from '../../../../util/LocalStorage';
import { SESSION_STATE, USER_DATA } from '../../../../util/constants/AppConstants';

import HashtagIcon from '../../../../assets/images/icons/hashtag.svg';
import './Hashtags.scss';

const Hashtags = ({ activeTopicId, userDataState }) => {
  const config = useSelector((state) => state.config.config);
  const session = getCustomState(SESSION_STATE);

  const [loading, setLoading] = useState(false);
  const [wordCloudData, setWordCloudData] = useState([]);

  const handleGetTopHashtags = async (id, userId) => {
    setLoading(true);

    const res = await getTopHashtags(config, id, userId);
    if (res.status === 'success') {
      setWordCloudData(res.data);
      setLoading(false);
    } else {
      console.log('ERROR: Grabbing Top Hashtags');
    }
  };

  useEffect(() => {
    if (config.api && session) {
      const userData = getCustomState(USER_DATA, true);
      handleGetTopHashtags(activeTopicId || 0, userData.id);
    }
  }, [config, session, userDataState, activeTopicId]);

  const handleWordCloudHover = () => {};

  return (
    <div className="top-words chart-component">
      <div className="title-top">
        <div className="chart-title align-left">
          <img src={HashtagIcon} /> Top Hashtags
        </div>
      </div>
      <div className="sentiment-charts">
        {!loading ? <WordCloudComponent data={wordCloudData} handleWordHover={handleWordCloudHover} /> : <Fader />}
      </div>
    </div>
  );
};

export default Hashtags;
