import axios from 'axios';

import { setAxiosConfig } from '../util/SetDefaultLists';

export const login = async (config, email, password) => {
  try {
    const res = await axios(
      setAxiosConfig(config, '/auth/login', 'post', {
        email,
        password,
      }),
    );
    return res.data;
  } catch (err) {
    console.error('Error logging in');
    return { error: err.message || 'Unexpected error' };
  }
};
