import React, { useEffect, useState } from 'react';

import PdfToImage from '../../../util/PdfToImage';
import { map } from '../../../util/constants/fakeMapData';

import './Map.scss';
import WardsMap from '../../components/WardsMap/WardsMap';

const Map = () => {
  const [mapType, setMapType] = useState('atlas');

  useEffect(() => {
    if (map.district.startsWith('Brampton')) {
      setMapType('wards');
    }
  }, []);

  return (
    <div className="platform-frame">
      <div className="elections map">
        <div className="pfr-header">
          <div className="dashboard-title">{`Electoral ${map.district} Map`}</div>
        </div>
        <ul className="map__tabs">
          {map.district.startsWith('Brampton') && (
            <li className="map__tab">
              <button
                type="button"
                className={`map__btn ${mapType === 'wards' ? 'active' : ''}`}
                onClick={() => setMapType('wards')}
              >
                Wards Map
              </button>
            </li>
          )}
          <li className="map__tab">
            <button
              type="button"
              className={`map__btn ${mapType === 'atlas' ? 'active' : ''}`}
              onClick={() => setMapType('atlas')}
            >
              Atlas Map
            </button>
          </li>
          <li className="map__tab">
            <button
              type="button"
              className={`map__btn ${mapType === 'detailed' ? 'active' : ''}`}
              onClick={() => setMapType('detailed')}
            >
              Overview Detailed Wall Map
            </button>
          </li>
        </ul>
        <div className="chart-component">
          {mapType === 'wards' ? (
            <WardsMap />
          ) : (
            <PdfToImage fileName={mapType === 'atlas' ? map.atlasMap : map.detailedMap} />
          )}
        </div>
      </div>
    </div>
  );
};

export default Map;
