import { useEffect } from 'react';

const useOutsideClick = (ref1, ref2, isOpen, closeFn) => {
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (
        ref1.current &&
        !ref1.current.contains(e.target) &&
        (!ref2 || (ref2.current && !ref2.current.contains(e.target)))
      ) {
        closeFn(false);
      }
    };

    if (isOpen) {
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isOpen, ref1, ref2, closeFn]);
};

export default useOutsideClick;
