export const elections = {
  candidates: [
    {
      party: 'Progressive Conservative',
      partyColor: '#9999FF',
      candidate: 'Todd Smith',
      votes: 21381,
      percent: 49.30,
      percentChange: 1.28,
      isChangePositive: true,
      expenditures: 76706,
    },
    {
      party: 'New Democratic',
      partyColor: '#F4A460',
      candidate: 'Alison Kelly',
      votes: 9073,
      percent: 20.92,
      percentChange: 10.92,
      isChangePositive: false,
      expenditures: 55474,
    },
    {
      party: 'Liberal',
      partyColor: '#EA6D6A',
      candidate: 'Emilie Leneveu',
      votes: 8003,
      percent: 18.45,
      percentChange: 3.56,
      isChangePositive: true,
      expenditures: 31230,
    },
    {
      party: 'Green',
      partyColor: '#99C955',
      candidate: 'Erica Charlton',
      votes: 2719,
      percent: 6.27,
      percentChange: 2.84,
      isChangePositive: true,
      expenditures: 0,
    },
    {
      party: 'New Blue',
      partyColor: '#02478d',
      candidate: 'Rob Collins',
      votes: 1128,
      percent: 2.60,
      percentChange: null,
      isChangePositive: null,
      expenditures: 5066,
    },
    {
      party: 'Ontario Party',
      partyColor: '#112f53',
      candidate: 'Noah Wales',
      votes: 1062,
      percent: 2.45,
      percentChange: null,
      isChangePositive: null,
      expenditures: 3779,
    },
  ],
  validVotes: {
    total: 43366,
    percent: 99.59,
    percentChange: 0.68,
    isChangePositive: true,
  },
  rejectedVotes: {
    total: 179,
    percent: 0.41,
    percentChange: 0.68,
    isChangePositive: false,
  },
  turnout: {
    total: 43545,
    percent: 45.91,
    percentChange: 13.07,
    isChangePositive: false,
  },
  swing: {
    percentChange: 6.1,
    isChangePositive: true,
  },
  election: '2022 Ontario general election',
  district: 'Bay of Quinte',
  expenseLimit: 133230,
  eligibleVoters: 95164,
  winner: 'Progressive Conservative',
  prevWinner: null,
};

// export const elections = {
//   candidates: [
//     {
//       party: 'Progressive Conservative',
//       partyColor: '#9999FF',
//       candidate: 'Graham McGregor',
//       votes: 13509,
//       percent: 44.99,
//       percentChange: 8.7,
//       isChangePositive: true,
//       expenditures: null,
//     },
//     {
//       party: 'Liberal',
//       partyColor: '#EA6D6A',
//       candidate: 'Harinder K Malhi',
//       votes: 8639,
//       percent: 28.77,
//       percentChange: 7.55,
//       isChangePositive: true,
//       expenditures: null,
//     },
//     {
//       party: 'New Democratic',
//       partyColor: '#F4A460',
//       candidate: 'Sandeep Singh',
//       votes: 5949,
//       percent: 19.81,
//       percentChange: 17.73,
//       isChangePositive: false,
//       expenditures: null,
//     },
//     {
//       party: 'Green',
//       partyColor: '#99C955',
//       candidate: 'Aneep Dhade',
//       votes: 895,
//       percent: 2.98,
//       percentChange: 0.47,
//       isChangePositive: false,
//       expenditures: null,
//     },
//     {
//       party: 'New Blue',
//       partyColor: '#02478d',
//       candidate: 'Jerry Fussek',
//       votes: 610,
//       percent: 2.03,
//       percentChange: null,
//       isChangePositive: null,
//       expenditures: null,
//     },
//     {
//       party: 'Ontario Party',
//       partyColor: '#112f53',
//       candidate: 'Julia Bauman',
//       votes: 423,
//       percent: 1.41,
//       percentChange: null,
//       isChangePositive: null,
//       expenditures: null,
//     },
//   ],
//   validVotes: {
//     total: 30025,
//     percent: 100,
//     percentChange: null,
//     isChangePositive: null,
//   },
//   rejectedVotes: {
//     total: 203,
//     percent: null,
//     percentChange: null,
//     isChangePositive: null,
//   },
//   turnout: {
//     total: 30228,
//     percent: 38.46,
//     percentChange: null,
//     isChangePositive: null,
//   },
//   swing: {
//     percentChange: 0.58,
//     isChangePositive: true,
//   },
//   election: '2022 Ontario general election',
//   district: 'Brampton North',
//   expenseLimit: null,
//   eligibleVoters: 78501,
//   winner: 'Progressive Conservative',
//   prevWinner: 'Independent',
// };

// export const elections = {
//   candidates: [
//     {
//       party: 'Progressive Conservative',
//       partyColor: '#9999FF',
//       candidate: 'Greg Rickford',
//       votes: 9567,
//       percent: 59.57,
//       percentChange: 11.01,
//       isChangePositive: true,
//       expenditures: 56299,
//     },
//     {
//       party: 'New Democratic',
//       partyColor: '#F4A460',
//       candidate: 'JoAnne Formanek Gustafson',
//       votes: 3199,
//       percent: 19.92,
//       percentChange: 17.41,
//       isChangePositive: false,
//       expenditures: 14415,
//     },
//     {
//       party: 'Liberal',
//       partyColor: '#EA6D6A',
//       candidate: 'Anthony Leek',
//       votes: 1823,
//       percent: 11.35,
//       percentChange: 0.77,
//       isChangePositive: true,
//       expenditures: 32991,
//     },
//     {
//       party: 'Green',
//       partyColor: '#99C955',
//       candidate: 'Catherine Kiewning',
//       votes: 608,
//       percent: 3.79,
//       percentChange: 0.26,
//       isChangePositive: true,
//       expenditures: 1339,
//     },
//     {
//       party: 'New Blue',
//       partyColor: '#02478d',
//       candidate: 'Kelvin Boucher-Chicago',
//       votes: 393,
//       percent: 2.45,
//       percentChange: null,
//       isChangePositive: null,
//       expenditures: 4074,
//     },
//     {
//       party: 'Ontario Party',
//       partyColor: '#112f53',
//       candidate: 'Larry Breiland',
//       votes: 276,
//       percent: 1.72,
//       percentChange: null,
//       isChangePositive: null,
//       expenditures: 0,
//     },
//     {
//       party: 'Consensus Ontario',
//       partyColor: '#00BAF2',
//       candidate: 'Richard A. Jonasson',
//       votes: 98,
//       percent: 0.61,
//       percentChange: null,
//       isChangePositive: null,
//       expenditures: 0,
//     },
//     {
//       party: 'Independent',
//       partyColor: '#DCDCDC',
//       candidate: "Mi'Azhikwan",
//       votes: 95,
//       percent: 0.59,
//       percentChange: null,
//       isChangePositive: null,
//       expenditures: 0,
//     },
//   ],
//   validVotes: {
//     total: 16059,
//     percent: 98.56,
//     percentChange: 0.48,
//     isChangePositive: false,
//   },
//   rejectedVotes: {
//     total: 235,
//     percent: 1.44,
//     percentChange: 0.48,
//     isChangePositive: true,
//   },
//   turnout: {
//     total: 39591,
//     percent: 43.22,
//     percentChange: 11.95,
//     isChangePositive: false,
//   },
//   swing: {
//     percentChange: 14.21,
//     isChangePositive: true,
//   },
//   election: '2022 Ontario general election',
//   district: 'Kenora—Rainy River',
//   expenseLimit: 66892,
//   eligibleVoters: 91954,
//   winner: 'Progressive Conservative',
//   prevWinner: null,
// };
