import axios from 'axios';

import { setAxiosConfig } from '../util/SetDefaultLists';

const fetchData = async (config, endpoint, params) => {
  try {
    const url = `${endpoint}?${new URLSearchParams(params).toString()}`;
    const res = await axios(setAxiosConfig(config, url));
    return res.data;
  } catch (err) {
    console.error(`Error fetching data from ${endpoint}:`, err);
    return { error: err.message || 'Unexpected error' };
  }
};

export const getEngagementsData = (config, id, user) => fetchData(config, '/data/engagements', { id, user });

export const getTopHashtags = (config, id, user) => fetchData(config, '/data/hashtags', { id, user });

export const getKeywords = (config, id, user) => fetchData(config, '/data/keywords', { id, user });

export const getMentionsData = (config, id, user) => fetchData(config, '/data/mentions', { id, user });

export const getTopNewsPosts = (config, id, user) =>
  fetchData(config, '/data/posts', { id, source: 1, latest: true, user_id: user });

export const getSentimentSources = (config, id, user) => fetchData(config, '/data/sentiment/source', { id, user });

export const getSentimentTrendData = (config, id, user) => fetchData(config, '/data/sentiment/trend', { id, user });

export const getTopSharedLinks = (config, id, user) => fetchData(config, '/data/links', { id, user });

export const getTopSocialPosts = (config, id, user, source) =>
  fetchData(config, '/data/posts', { id, source, latest: true, user_id: user });

export const getTopics = (config, id, user) => fetchData(config, '/data/list', { id, user_id: user });
