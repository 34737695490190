/**
 *  @ These are all the app specific constants we will be using:
 */
export const SESSION_STATE = 'SESSION_STATE';
export const SESSION_ID = 'SESSION_ID';
export const USER_DATA = 'USER_DATA';
export const BACKGROUND_EFFECT = 'BACKGROUND_EFFECT';

export const USER_ROLES = [
  {
    label: 'Administrator',
    short: 'Admin',
    value: 1,
  },
  {
    label: 'Beta User',
    short: 'Beta',
    value: 2,
  },
  {
    label: 'General User',
    short: 'General',
    value: 3,
  },
];

export const units = ['', 'K', 'M', 'B', 'T', 'Q'];

export const categories = ['Searches', 'Announcements'];
