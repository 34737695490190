import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { setNotify } from '../../../datastore/actions/actionActions';

import './AppNotify.scss';

const AppNotify = () => {
  const dispatch = useDispatch();
  const notify = useSelector((state) => state.action.notify);

  const [classNames, setClassNames] = useState('notify');
  const [title, setTitle] = useState('Success');
  const [message, setMessage] = useState('You have successfully completed the action');

  useEffect(() => {
    if (notify.status) {
      if (notify.type === 'success') {
        setClassNames('notify success');
      }
      if (notify.type === 'error') {
        setClassNames('notify error');
      }
      setTitle(notify.title);
      setMessage(notify.message);
      setTimeout(
        () =>
          dispatch(
            setNotify({
              status: false,
              type: null,
              title: '',
              message: '',
            }),
          ),
        5000,
      );
    }
  }, [notify]);

  if (!notify.status) {
    return null;
  }

  return (
    <div className={classNames}>
      <div className="notify-title">{title}</div>
      <div className="notify-message">{message}</div>
    </div>
  );
};

export default AppNotify;
